.input-pass-background {
    background: url('src/assets/newbg.jpeg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    min-width: 100vw;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.input-pass-box {
    width: fit-content;
    /* background: url('src/assets/blue_bg.jpg'); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* flex: 1; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem 2rem 3rem 2rem;
}

#title1 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

#title2 {
    font-size: 2rem;
    margin-bottom: 0.5rem;
}

#pass-input {
    font-size: 1.5rem;
    font-family: 'Amadeus';
    text-align: center;
    width: 100%;
    border-width: 0px;
    padding: 0px;
    border: 0px;
    color: rgb(187,189,191);
    background-color: rgba(255,255,255,0.2);
    height: 44px;
    border-top-left-radius: 44px;
    border-bottom-left-radius: 44px;
    outline: none;
}

#pass-input::placeholder {
    color: white;
    opacity: 1; /* Firefox */
}
  
#pass-input:-ms-input-placeholder { /* Internet Explorer 10-11 */
   color: white;
}
  
#pass-input::-ms-input-placeholder { /* Microsoft Edge */
   color: white;
}

.input-container {
    display: flex;
    width: 50%;
    padding: 0px;
    border: 0px;
    margin-top: 1rem;
    height: 44px;
}

.btn-arrow {
    background-color: rgb(1,188,241);
    display: flex;
    width: 34px;
    padding: 8px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 100%;
    transform: translateX(-22px);
}

.slick-dots li button {
    background-color: transparent !important;
}