.btn-container {
    display: flex;

    
}

.btn-container button {
    padding: 15px 45px;
    margin: 15px;
    border-radius: 30px;
    border: none;
    outline: none;
    font-size: 25px;
    color: #000;
    background-color: white;
    font-weight: 300;
}

.btn-container button:hover {
    cursor: pointer;
    background-color: rgb(1,188,241);
    color: white;
    
}

@media screen and (max-width: 480px) {
    .btn-container {
        flex-direction: column;
        
    }  
}

.top-left-container {
    position: absolute;
    top: 20px;
    left: 20px;
}