.g-noram, .g-latam, .g-eur, .g-apac, .g-africa, .g-middle_east {
    opacity: 0.4;
}

.g-map-active {
    opacity: 1;
    /* cursor: pointer; */
}

.map-pin {
    visibility: hidden;
}

.map-pin-active {
    /* transform: translateY(200,300); */
    visibility: visible;
    animation:mymove 0.5s ease-out forwards;
    /* animation-iteration-count:1; */
    /* Safari and Chrome */
    -webkit-animation:mymove 0.5s;
    /* -webkit-animation-iteration-count:1; */
}

@keyframes mymove
{ from {top:0px; opacity: 0;}
to {top:200px; opacity: 1}
}

@-webkit-keyframes mymove /* Safari and Chrome */
{
from {top:0px; opacity: 0;}
to {top:200px; opacity: 1;}
}

@media screen and (max-width: 480px) {
    svg {
        transform: translateY(-30%);
    }
}