.detail-header-container {
    border-style: solid;
    border-width: 1px 0px 1px 0px;
    border-color: rgb(14, 123, 188);
    margin-left: 1rem;
    margin-right: 1rem;
    /* padding-left: 2rem;
    padding-right: 2rem; */
    display: flex;
    flex-direction: row;
    padding: 1rem 2rem 1rem 2rem;
}

.header-item-border {
    border-width: 0px 1px 0px 0px;
    border-color: rgb(14, 123, 188);
    border-style: solid;
}

.header-item {
    /* padding: 5px; */
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.header-item img {
    width: auto;
    height: 3.5rem;
}

.header-item span {
    color: white;
    font-size: 1.5rem;
    text-transform: uppercase;
    margin-top: 0.5rem;
}

.detail-content-container {
    padding: 1rem 2rem 1rem 2rem;
    margin-left: 1rem;
    margin-right: 1rem;
    display: flex;
    flex-direction: row;
    flex: 1;
}

.detail-content-container div {
    display: flex;
    flex-direction: column;
}

.partner-item {
    display: flex;
    flex-direction: row !important;
    align-items: stretch;
    min-height: fit-content;
    scroll-snap-align: start;
}

.image-partner {
    /* margin-top: 15px;
    margin-bottom: 15px;
    cursor: pointer; */
    border-color: transparent;
    border-style: solid;
    border-width: 1px;
    border-style: solid;
    margin-top: 1rem;
    margin-bottom: 1rem;
    height: auto;
    width: 100%;
    cursor: pointer;
}

.image-partner-active {
    border-color: white;
    border-style: solid;
    border-width: 1px;
    border-style: solid;
    margin-top: 1rem;
    margin-bottom: 1rem;
    height: auto;
    width: 100%;
}

.image-card {
    /* margin-top: 15px;
    margin-bottom: 15px;
    cursor: pointer; */
    border-color: transparent;
    border-width: 1px;
    border-style: solid;
    margin-top: 1rem;
    margin-bottom: 1rem;
    height: auto;
    width: 100%;
    cursor: pointer;
    background-color: white;
}

.image-card-active {
    border-color: transparent;
    border-style: solid;
    border-width: 1px;
    margin-top: 1rem;
    margin-bottom: 1rem;
    height: auto;
    width: 100%;
    background-color: white;
}

.active-line {
    background-color: white;
}

.partner-item-spacer {
    width: 2rem;
    height: 1px;
    /* margin-left: 1px; */
    align-self: center;
}

.vertical-line {
    width: 1px;
    flex-direction: column !important;
    display: flex;
}

.vertical-line .half {
    height: 100%;
    width: 1px;
}

.vertical-line .half-active {
    height: 100%;
    width: 1px;
    background-color: white;
}

.card-item {
    margin-bottom: 20px;
    display: flex;
    flex-direction: row !important;
    align-items: stretch;
}

.card-item-spacer {
    width: 2rem;
    height: 1px;
    margin-left: 1px;
}

.card-item-active img {
    border-color: white;
    border-width: 1px;
    border-style: solid;
}

.card-item-active .active-line {
    background-color: white;
}

.cards-container {
    /* margin-top: 15px; */
    border-color: white;
    border-width: 0px 0px 0px 1px;
    border-style: solid;
    flex: 1;
}

.currencies-container {
    padding-top: 15px;
    border-color: white;
    border-width: 0px 0px 0px 1px;
    border-style: solid;
    display: flex;
    flex-direction: row !important;
    flex-wrap: wrap;
    flex: 1;
    align-content: flex-start;
}

.currency-item {
    width: 50%;
    height: 1.7rem !important;
    display: flex;
    flex-direction: row !important;
    align-items: center;
    line-height: 15px;
}

@media screen and (max-width: 900px) {
    .currency-item {
        width: 100% !important;
    }
}

.currency-item span {
    font-size: 1.1rem;
    margin-left: 0.7rem;
}

.currency-item span:last-child {
    font-size: 0.8rem;
}

.scrollable-col {
    overflow: auto;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
    scroll-snap-type: y mandatory;
}

.scrollable-col::-webkit-scrollbar {
    display: none;
}

.disable-img {
    filter: grayscale(100%);
}

.disable-card-img {
    opacity: 0.3;
}

.arrow-icon-bottom {
    width: 0.9rem;
    height: 0.9rem; 
    margin-top: -2.5rem;
    margin-bottom: 1.5rem; 
    align-self: center;
    z-index: 99;
}

.absolute-mask {
    display: flex;
    justify-content: center;
    /* margin-right: 1.3rem; */
    margin-left: -1.3rem;
}

.arrow-icon-right {
    width: 0.9rem;
    height: 0.9rem; 
    z-index: 99;
}

.partner-item-description {
    text-align: center;
    color: white;
    margin: 0;
}

.funding-text {
    color: white;
    margin-left: 20px;
    margin-top: 20px;
    align-self: flex-start;
    /* width: 100%; */
}