@font-face {
    font-family: 'Amadeus';
    src: url('Amadeus-Light.woff2') format('woff2'),
        url('Amadeus-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Amadeus';
    src: url('Amadeus-Regular.woff2') format('woff2'),
        url('Amadeus-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Arial';
    src: url('Arial-BoldMT.woff2') format('woff2'),
        url('Arial-BoldMT.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

