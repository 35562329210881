.card-scheme-provider-grid {
  border-color: white;
  border-width: 0px 0px 0px 1px;
  border-style: solid;
  display: flex;
  flex: 1;
  height: 100%;
  width: 100%;
  flex-wrap: wrap;
  padding: 20px;
  box-sizing: border-box;
  align-items: center;
  margin-top: -15px;
}

.connect-line {
  height: 1px;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 0;
}

.card-scheme-provider-container {
  background-color: #ccc;
  opacity: 0.6;
  cursor: pointer;
}

.card-scheme-provider-container.selected {
  opacity: 1;
}