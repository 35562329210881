.bg-blue {
    background: url('src/assets/blue_bg.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    min-width: 100vw;
    flex: 1;
}

.header-title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
}

#header-title {
    font-size: 2.8rem;
}