.text-bold {
  font-family: 'Arial';
  font-weight: bold;
  color: white;
}

.text-regular {
  font-family: 'Amadeus';
  font-weight: normal;
  color: white;
}

.text-light {
  font-family: 'Amadeus';
  font-weight: 300;
  color: white;
}

.animated-line {
  -webkit-transition: width 10s ease-in-out;
  -moz-transition: width 10s ease-in-out;
  -o-transition: width 10s ease-in-out;
  transition: width 10s ease-in-out;
}
