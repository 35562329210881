.page-container {
    padding: 1rem 5rem;
    /* padding-top: 1rem; */
    display: flex !important;
    flex-direction: row;
    flex-wrap: wrap;
    /* height: calc( 100vh - 116px); */
    /* justify-content: center; */
}

.country-item {
    margin: 0rem 1rem 0rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100px;
    cursor: pointer;
}

.country-flag {
    width: 100%;
    height: 70px;
    background-size: cover;
    margin-bottom: 8px;
    background-color: rgba(255,255,255,0.1);
}

.disable-img {
    filter: grayscale(100%);
    cursor: default !important;
}

.country-item span {
    color: white;
    text-transform: uppercase;
    font-size: 0.8rem;
    text-align: center;
    height: 47px;
}

.dot__ button:before {
    border: 1px solid white;
    border-radius: 100%;
    color: transparent !important;
    font-size: 18px;
    width: 15px !important;
    height: 15px !important;
}

.dot__ .slick-active button:before {
    background-color: white;
    border: 1px solid transparent;
    /* color: white !important; */
    font-size: 18px;
}