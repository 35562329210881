.dots-container {
    flex-direction: row;
    display: flex;
    justify-content: center;
}

.dot {
    width: 10px;
    height: 10px;
    border-radius: 6px;
    border-style: solid;
    border-width: 1px;
    border-color: white;
    margin: 2px;
    cursor: pointer;
}

.active-dot {
    background-color: white;
    cursor: default !important;
}